import Topbar from "../../../components/organisms/topbar"
import React, { useEffect, useState } from "react"
import CustomLayout from "../../../Layouts/Layout"
import ContentPageDetailNews from "../../../domain/news/DetailNews"

const DetailNewIndex = (props) => {
  const [isLoaded, setIsLoaded] = useState(false)
  useEffect(() => {
    setIsLoaded(true)
  }, [])

  return (
    <>
      {isLoaded && document && (
        <div>
          <Topbar />
          <CustomLayout
            selectedPage={<ContentPageDetailNews id={props?.id} />}
          />
        </div>
      )}
    </>
  )
}

export default DetailNewIndex
